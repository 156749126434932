import React from 'react';

const CookiePolicy = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', fontFamily: 'Arial, sans-serif' }}>
      <h1>Cookie Policy</h1>
      <p>
        This Cookie Policy explains how <strong>Launchiam Inc.</strong> (“we”, “us”, or “our”) uses cookies and similar
        tracking technologies on the KeyRunner website and related online services.
      </p>

      <h2>1. What Are Cookies?</h2>
      <p>
        Cookies are small text files that are placed on your device (computer, tablet, or mobile) when you visit a
        website. They help the website recognize your device, enhance user experience, and track certain information about
        your visit. Cookies may be “persistent” or “session” cookies.
      </p>

      <h2>2. Types of Cookies We Use</h2>
      <p>We use the following types of cookies on the KeyRunner website:</p>
      <ul>
        <li>
          <strong>Essential Cookies:</strong> These cookies are necessary for the website to function properly. Without
          these cookies, some parts of the site may not work as intended.
        </li>
        <li>
          <strong>Performance Cookies:</strong> These cookies collect information about how visitors use our website,
          including which pages are visited most frequently. This data helps us improve the website's functionality.
        </li>
        <li>
          <strong>Functional Cookies:</strong> These cookies allow the website to remember choices you have made in the
          past, such as your username or language preference, to provide a personalized experience.
        </li>
        <li>
          <strong>Marketing Cookies:</strong> These cookies track your online activity and help us deliver relevant
          advertisements. These cookies may also limit the number of times you see an ad and help measure the effectiveness
          of advertising campaigns.
        </li>
      </ul>

      <h2>3. How We Use Cookies</h2>
      <p>
        We use cookies to understand how users interact with our website, improve user experience, and tailor content to
        users’ preferences. Cookies may also be used to analyze website traffic and for advertising purposes.
      </p>

      <h2>4. Third-Party Cookies</h2>
      <p>
        We may allow third-party service providers, such as analytics or advertising services, to place cookies on your
        device when you visit our website. These third parties may collect information about your activities on our
        website and other sites to provide you with personalized advertising or to improve their services.
      </p>

      <h2>5. Managing Cookies</h2>
      <p>
        You can control and manage cookies through your browser settings. Most browsers allow you to block or delete
        cookies. However, disabling cookies may affect your ability to use certain features of our website. Below are links
        to manage cookies in popular browsers:
      </p>
      <ul>
        <li><a href="https://support.google.com/chrome/answer/95647">Google Chrome</a></li>
        <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla Firefox</a></li>
        <li><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">Apple Safari</a></li>
        <li><a href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-in-internet-explorer-168dab11-0753-043d-7c16-ede5947fc64d">Microsoft Internet Explorer</a></li>
      </ul>

      <h2>6. Consent to Use Cookies</h2>
      <p>
        By using our website, you consent to our use of cookies as outlined in this policy. If you do not agree with our
        use of cookies, you may opt to disable them in your browser as described above.
      </p>

      <h2>7. Updates to This Cookie Policy</h2>
      <p>
        We may update this Cookie Policy from time to time. Any changes will be posted on this page with an updated
        effective date. We encourage you to review this policy regularly to stay informed about how we use cookies.
      </p>

      <h2>8. Contact Information</h2>
      <p>
        If you have any questions about this Cookie Policy, please contact us at: <br />
        <strong>Email:</strong> contact@keyrunner.app <br />
        <strong>Address:</strong> Launchiam Inc., 10601 Clarence Dr. Suite 250,Frisco, TX 75033
      </p>
    </div>
  );
};

export default CookiePolicy;
    