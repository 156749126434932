import React from 'react'
import playimg from '../../assets/images/loginsideimg.png'
import whtlogo from '../../assets/images/whtlogo.png'
import '../Login/Login.css'

export default function UserLogin() {
  return (
    <section className="fullsection loginbox">
      <div className="loginboxinner">
      <div className="smcontainer mx-auto loginboxparts">
      <div className="w-full lg:w-1/2 loginleft">
        <div className='leftbox'>
            <div className='logwht'><img src={whtlogo} alt="whtlogo" className="w-full h-auto" /></div>
            <div className='centerimg'><img src={playimg} alt="playimg" className="w-full h-auto" /></div>
            <div className='logintxt'><h5>Speady, Easy and Fast</h5>
            <p>The Zero Trust Rest API Client</p></div>
        </div>
      </div>
      <div className="w-full lg:w-2/5 loginright">
        <div className='rightbg'>
          <div>
            <h1>Login</h1>
            <form className='loginform'>
            <>
              <div className="mail_layout">
                <div className="input-group input_mail">
                  
                  <input
                    type="email"
                    name="user_email"
                    className="form-control"
                    id="email"
                    placeholder="Your email"
                    
                  />
                </div>
              </div>
              <button
                className="flex items-center submitbtn"
                
              >
                
                <span className="btntxt">Let's go</span>
              </button>
            </>
          </form>
          </div>
        </div>

      </div>
      
        
      </div>
      </div>
    </section>
  )
}
