import React from "react";
import "../../Company/Company.css";
import Search from "../../../assets/images/searchicon.svg";
import keyicon from "../../../assets/images/keyicon.svg";
import Scenarios from "../../../assets/images/scenerioicon.svg";

const counterData = [
  {
    id: 1,
    h6Text: "Founded",
    h2Text: "2023",
    imgSrc: Search,
  },
  {
    id: 2,
    h6Text: "Keys",
    h2Text: "10k+",
    imgSrc: keyicon,
  },
  {
    id: 3,
    h6Text: "Scenarios",
    h2Text: "100k+",
    imgSrc: Scenarios,
  },
  // Add more data objects as needed
];

export default function CompanyBanner() {
  return (
    <>
      <section className="fullsection companybannerbox">
        <div className="companybannerinner">
          <div className="smcontainer mx-auto text-center">
            <h1 className="maintitle">
              <span className="bgtxt">Seamless Solution for</span>
              <br /> Modern Challenges{" "}
            </h1>
            <p className="subtext">
              Crafting Solutions for Effortless Integration, Performance, and
              Monitoring
            </p>
          </div>
        </div>
      </section>
      <section className="fullsection couterbox">
        <div className="counterinner">
          <div className="smcontainer mx-auto text-center">
            <div className="counterparts">
              {counterData.map((item) => (
                <div key={item.id} className="counterdata">
                  <div>
                    <h6>{item.h6Text}</h6>
                    <img src={item.imgSrc} />
                    <h2>{item.h2Text}</h2>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
