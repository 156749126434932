import React from 'react';
import { Link } from 'react-router-dom';

export default function Headernav() {
  return (
    <div className="menu-content">
          <ul>
            
            <Link to="/features">Features</Link>
            <a href="https://docs.keyrunner.app/">Docs</a>
            <Link to="/pricing">Pricing</Link>
            <Link to="/usecases">Use Cases</Link>
            <Link to="/company">Company</Link>
            <Link to="/contact">Contact</Link>
            <a href="https://github.com/launchiamenterprise/keyrunner/issues">Support</a>
            {/* <li><Link to="/login" className="loginbtn">Login</Link></li> */}
            {/* <li><Link to="/login" className="signbtn">Sign Up</Link></li> */}
          </ul>
        </div>
  )
}


