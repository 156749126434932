import React from "react";
import playgroundimg from '../../../assets/images/playground.png';
import arrowimg from '../../../assets/images/arrow.svg';
import '../Homepage.css'
import { Link } from 'react-router-dom';

export default function Playground() {
  return (
    <section className="fullsection playgroundbox">
      <div className="playgroundboxinner">
      <div className="smcontainer mx-auto text-center">
      <h1 className="maintitle">Playground & <span className="bgtxt">Key Runner</span></h1>
      <p className="subtext">Streamline Comprehensive testing and integration scenarios effortlessly 
      by organizing related requests into collections, ensuring a <br /> structured and systematic 
      approach without manually inputting the secrets and keys. This minimizes the attack 
      surface and reduces <br /> the potential of secrets being exposed, enhancing security.</p>
      <div className="w-full playboyimg">
          <img src={playgroundimg} alt="Banner" className="w-full h-auto" />
        </div>
        <Link to="/contact">
          <button className="ctabtn flex items-center">
            <span className="arrowimg"><img src={arrowimg} alt="arrowimg" className="w-full h-auto" /></span>
            <span className="btntxt">Contact Us</span>
            </button>  </Link>
      </div>
      </div>
    </section>
  );

}
