import React from 'react';
import Logoimg from '../../assets/images/LOGO.svg';
import bottoming from '../../assets/images/footerbottom.png';
import '../Footer/Footer.css'
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-white p-8 mainfooter">
      <div className="smcontainer max-w-screen-xl mx-auto">
        <div className='fullfooter'>
        {/* Column 1 */}
        <div className='footcol1 footercolumn'>
        <img src={Logoimg} alt="Logo" />
          
        </div>

        {/* Column 2 */}
        <div className='footcol2 footercolumn'>
          <h2 className="">PRODUCT</h2>
          <ul>
          <li><Link to="/company">Company</Link></li>
            <li><Link to="/features">Features</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/usecases">Use Cases</Link></li>
          </ul>
        </div>

        {/* Column 3 */}
        <div className="footcol3 footercolumn">
          <h2 className="">Policies</h2>
          <ul>
          <li><Link to="/TOU">Terms of use</Link></li>
          <li><Link to="/dpa">Data Processing Agreement</Link></li>
            <li><Link to="/privacypolicy">Privacy Policy</Link></li>
            <li><Link to="/cookiepolicy">Cookie Policy</Link></li>
            <li><Link to="/license">License</Link></li>
            <li> <a href="https://blog.keyrunner.app/">Blog</a></li>
          
          </ul>
        </div>

        {/* Column 4 */}
       

        {/* Column 5 */}
        <div className="footcol5 footercolumn">
        <img src={bottoming} alt="Logo" />
        </div>
        </div>
      </div>
    </footer>
  )
}
