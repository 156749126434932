import React from 'react'
import { Collapse } from 'antd';

const { Panel } = Collapse;



const items = [
    {
      key: '1',
      label: 'What does the product do?',
      children: <p>KeyRunner securely manages API interactions by encrypting and locally storing sensitive data such as API keys,Admin Creds and tokens on users machine if local lite is being used. In our enterprise 
        version keyrunner's data pane is connected to the secret stores to fetch this data.
      </p>,
    },
    {
      key: '2',
      label: 'How does the product eliminate manual input of secret keys and API keys?',
      children: <p>Keyrunner employs a automated approach without human intervention by fetching the secrets from secret stores with machine-machine approach, ensuring enhanced security. 
       When application need these secrets, they are fetched automatically, eliminating the need for manual input and reducing the risk.</p>,
    },
    {
      key: '3',
      label: 'What is the significance of overseeing OAuth token lifecycles?',
      children: <p>OAuth tokens play a crucial role in granting secure access to resources. Our product ensures proper management of these tokens throughout their lifecycle, 
        from creation to expiration. This oversight enhances security and reduces the chances of unauthorized access.</p>,
    },
    {
        key: '4',
        label: 'How does the product facilitate regression testing for API Development & testing?',
        children: <p>KeyRunner facilitates regression by providing a secure environment to test API changes without exposing sensitive keys or data, aligning with Zero Trust's emphasis on minimizing risk. 
          Our product provides tools and mechanisms to automate the testing process. This helps ensuring that your systems remain stable and secure.</p>,
      },
      {
        key: '5',
        label: 'How does the product enhance security for APIs?',
        children: <p>By dynamically fetching and securely storing secrets in local lite version and fetching sensitive data via data pane in enteprise version, eliminating manual input of sensitive keys, and providing oversight over OAuth tokens, 
          the product reduces the attack surface and the potential for human-related security risks. This comprehensive approach enhances the overall security of organizations</p>,
      },
  ]

export default function Faq() {
    
  return (
    <>
    <section className="fullsection faqbox">
          <div className="faqboxinner">
          <div className="smcontainer mx-auto">
          <h1 className="maintitle maintitlewht text-center">Frequently <span className="bgtxt">asked questions</span></h1>
            <div className='faqlist'>
            <Collapse accordion items={items} defaultActiveKey={['1']}/>
            </div>
          </div>
          </div>
        </section>
    </>
  )
}
