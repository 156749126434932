import React, { useEffect, useState,useRef  } from 'react';
import TabPane from 'antd/es/tabs/TabPane';
import { Tabs } from 'antd';


const onChange = (key) => {
  console.log(key);
};
const items = [
  {
    key: '1',
    label: 'Playground - No Code Automation',
    children: (
      <div className='tabcontent' onScroll={true}>
        <img src={require('../../../assets/images/plaground.png')}/>
        <h2>Playground - No Code Automation</h2>
        <p>No code Automation. Build trees and nodes with existing API collections, a drag and drop functionality to play around with test cases and never to miss an edge case</p>
      </div>
    ),
  },
  {
    key: '2',
    label: 'KeyRunner',
    children: (
      <div className='tabcontent'onScroll={true}>
        <img src={require('../../../assets/images/requests.png')}/>
        <h2>KeyRunner</h2>
        <p>Zero Trust API Client for any use case</p>
      </div>
    ),
  },
  {
    key: '3',
    label: 'Test case Performance',
    children: (
      <div className='tabcontent'onScroll={true}>
        <img src={require('../../../assets/images/Dashboard .png')}/>
        <h2>Test case Performance</h2>
        <p>Evaluate how well individual test cases & Regression test cases execute and identify bottlenecks.</p>
      </div>
    ),
  },
  {
    key: '4',
    label: 'Regression',
    children: (
      <div className='tabcontent'>
        <img src={require('../../../assets/images/playground.png')}/>
        <h2>Regression</h2>
        <p>Continuously assess IAM systems and app integrations. Mitigating human errors and swiftly identifying vulnerabilities, enhancing overall security posture</p>
      </div>
    ),
  },
  {
    key: '5',
    label: 'App to App Monitoring',
    children: (
      <div className='tabcontent'>
        <img src={require('../../../assets/images/tabimg.png')}/>
        <h2>App to App Monitoring</h2>
        <p>Verify the security of app-to-app integrations involving non-human identities</p>
      </div>
    ),
  },
  {
    key: '6',
    label: 'Configuration Management',
    children: (
      <div className='tabcontent'>
        <img src={require('../../../assets/images/Environment.jpeg')}/>
        <h2>Environment Configuration</h2>
        <p>Configure settings to match different environments accurately.</p>
      </div>
    ),
  },
];

export default function VerticalTabs() {
  return (
    <section className="fullsection verticallist">
      <div className="verticallistinner">
        <div className="smcontainer mx-auto">
    <div className="vertical-tabs-container">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} tabPosition="left" style={{ height: '100%' }} />
    </div>
    </div>
    </div>
    </section>
  )
}


