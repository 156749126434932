import React from 'react';
import '../Company/Company.css'
import CompanyBanner from './CompanyBanner/CompanyBanner';
import About from './About/About';
import OurStory from './OurStory/OurStory';
import Process from '../Home/ProcessSection/Process';


export default function Company() {
  return (
    <>
    <CompanyBanner />
    <About />
    <OurStory />
    <Process />
    </>
  )
}
