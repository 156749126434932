import React, { useState } from 'react';
import Logoimg from '../../assets/images/LOGO.svg';
import '../Header/Header.css';
import Headernav from './Headernav';



export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="mainheader">
      <div className="maxcontainer mx-auto flex justify-between items-center">
        <div className=" Headerleft">
          <a href="/" className="">
            <img src={Logoimg} alt="Logo" className="logo-image" />
          </a>
        </div>

        <div className="w-2/3 flex justify-end items-center sidemenu Headerright">
          <div className='dekstopmenu'><Headernav /></div>
        </div>
      </div>
      
      <div className='mobileheader'>
      <button className="menu-button" onClick={toggleMenu}>
            {isOpen ? <i className="fa fa-times"></i> : <i className="fa fa-bars"></i>}
      </button>

      {isOpen && <div className="menu-backdrop" onClick={toggleMenu}></div>}
      <div className={`menu ${isOpen ? 'open' : ''}`}>
        <Headernav />
      </div>
      </div>
    </header>
  );
}
