import React from 'react';
import './Privacypolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
    <h1>Privacy Policy</h1>

<h2>1. Introduction</h2>
<p>This Privacy Policy ("Policy") explains how LaunchIAM Inc., a company registered in the United States of America, collects, uses, and protects your personal information when you use KeyRunner, a product developed by LaunchIAM Inc. We are committed to safeguarding your privacy and ensuring the security of your data.</p>

<h2>2. Personal Information</h2>
<p>We do not collect sensitive data or personal information when you use KeyRunner, except for the limited account information required for account creation. This may include your username, email address, and other account-related details.</p>

<h2>3. Data Handling and Storage</h2>
<p>When you create an account for the Zero Trust Explorer and Pioneer versions of KeyRunner, it is important to note that LaunchIAM Inc. does not collect sensitive data, such as secrets, keys, or any other confidential information. All sensitive data provided by users during the use of KeyRunner will be stored in the Data Pane, which is a component residing in your client environment. No cloud storage or external servers are involved in storing customer data. This approach is designed to enhance the security and privacy of your sensitive information, ensuring that it remains under your control at all times.</p>

<h2>4. Confidentiality</h2>
<p>LaunchIAM Inc. places a high value on the confidentiality and security of your data. We do not access, view, or store your sensitive data. Our commitment is to ensure the protection and privacy of your information.</p>

<h2>5. User Responsibilities</h2>
<p>Users are responsible for managing and securing their account credentials. Please take necessary precautions to protect your account from unauthorized access.</p>

<h2>6. Data Ownership</h2>
<p>You retain full ownership and control of the data stored within your Data Pane. LaunchIAM Inc. does not claim any ownership or rights over your data.</p>

<h2>7. Compliance</h2>
<p>LaunchIAM Inc. is committed to maintaining compliance with relevant data protection and privacy regulations. By using KeyRunner, you agree to abide by our data handling and storage practices, as described in this Privacy Policy.</p>

<h2>8. Contact Information</h2>
<p>If you have any questions or concerns regarding this Privacy Policy or KeyRunner, please contact LaunchIAM Inc. at [contact@launchiam.com].</p>

<p>This Privacy Policy is designed to inform you of how we handle your data and protect your privacy when using KeyRunner. Your trust and confidence in our commitment to data security and privacy are of utmost importance to LaunchIAM Inc.</p>
    </div>
  );
}

export default PrivacyPolicy;
