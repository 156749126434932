import React, { useEffect,useRef } from "react";
import playground from '../../../assets/images/sideimg.png';
import arrowimg from '../../../assets/images/arrow.svg';
import vscodeimg from '../../../assets/images/vscode.svg';
import msstoreimg from '../../../assets/images/microsft.png';
import macimg from '../../../assets/images/apple.png';
import lottie from 'lottie-web';
import animationData from '../../../assets/images/LaunchIAM.json';





import '../Homepage.css'
import { Link } from 'react-router-dom';

import ReactGA from 'react-ga';


function BannerComponent() { 
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animationData,
      renderer: 'svg', // Use 'svg' or 'canvas' depending on your preference
      loop: true, // Set to false if you don't want it to loop
      autoplay: true, // Set to false if you want to control playback manually
    });

    return () => {
      anim.destroy(); // Cleanup animation when component unmounts
    };
  }, []);

  useEffect(() => {
    ReactGA.initialize('G-3SWQQ5P6VW', {
    });
    
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
function trackLinkClick(eventName) {
  ReactGA.event({
    category: 'Link Clicks',
    action: 'Click',
    value:'mac',
    label: eventName,
    callback: function() {
      
    }
  });
}



  return (
    <>
    <section className="fullsection bannerbox">
      <div className="smcontainer mx-auto flex flex-col lg:flex-row items-center justify-center space-y-4 md:space-y-0">

      <div className="w-full lg:w-1/2">
       
       <h1 className="maintitle maintitlewht "><span className="bgtxt">KeyRunner</span> -The Zero Trust API Client</h1>
       
       <div className="typewriter">
       <h2 className="subtext typewriter">"No Sign-up | Local Storage | Playground to run  Collections"</h2>
       </div>

       <div className="buttons-container">
      <a href="https://marketplace.visualstudio.com/items?itemName=KeyRunner.keyrunner" target="_blank" rel="noopener noreferrer" onClick={() => trackLinkClick('VSCodeExtension')}>
        <button className="ctabtn flex items-center">
          <span><img src={vscodeimg} alt="Logo" className="small-logo" /></span>
          <div className="button-text flex items-center justify-between">
            <span className="btntxt">Get VS Code Extension </span>
          </div>
        </button>
      </a>
     
      <a href="https://keyrunner.s3.us-west-2.amazonaws.com/keyrunner_1.0.63_x64_win.exe" target="_blank" rel="noopener noreferrer" onClick={() => trackLinkClick('VSCodeExtension')}>
      <button className="ctabtn flex items-center">
        <span><img src={msstoreimg} alt="Logo" className="small-logo" /></span>
        <span className="btntxt">Download for Windows</span>
      </button>
      </a>

      <a href="https://keyrunner.s3.us-west-2.amazonaws.com/keyrunner_1.0.63_x64_mac.dmg" target="_blank" rel="noopener noreferrer" onClick={() => trackLinkClick('VSCodeExtension')}>
      <button className="ctabtn flex items-center">
        <span><img src={macimg} alt="Logo" className="small-logo" /></span>
        <span className="btntxt">Download for Mac</span>
      </button>
      </a>

    </div>
</div>

<div className="w-full lg:w-2/5 vectorboy" ref={animationContainer}></div>
<span style={{color: '#2C64CC', fontWeight: 'bold', fontSize: '18px', fontFamily: 'Arial, sans-serif', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)'}}>
  Patent Pending
</span>

        {/* <div className="w-full lg:w-1/2 bannerimg">
          <img src={playground} alt="Banner" className="w-full h-auto" />
        </div> */}

      </div>
    </section>
    <div className="wavebg">
      <div className="blankspace"></div>
    </div>
    </>
  );
}

export default BannerComponent;
