import React from 'react';
import './TOU.css';

function TermsOfUse() {
  return (
    <div className="terms-of-use">
    
    <h2>Terms of Use & Service Agreement</h2>
    
    <h3>1. Introduction</h3>
    <p>These Terms of Use ("Terms") are a legal agreement between you and LaunchIAM Inc., a company registered in the United States of America, regarding the use of KeyRunner, a product developed by LaunchIAM Inc. KeyRunner is a Zero Trust API Client that provides various features and services. By using KeyRunner, you agree to comply with these Terms.</p>

    <h3>2. KeyRunner Versions</h3>
    <p>KeyRunner is available in three different versions:</p>
    
    <p><strong>a) Local Lite</strong></p>
    <ul>
        <li>This version is completely free to use.</li>
        <li>It offers local storage capabilities.</li>
        <li>No signup or registration is required.</li>
    </ul>
    
    <p><strong>b) Zero Trust Explorer</strong></p>
    <ul>
        <li>This plan includes all features of the Local Lite version.</li>
        <li>It provides the following additional features:</li>
        <ul>
            <li>Single Sign on(SSO)</li>
            <li>Role-Based Access Control (RBAC)</li>
            <li>Centralized data pane for reading secret keys and sensitive data without exposing them to users.</li>
            <li>PII information masking in responses, which can be configured by the data owner.</li>
            <li>Scheduler for running regression tests.</li>
        </ul>
        <li>Price: $10 per user/tenant.</li>
    </ul>
    
    <p><strong>c) Zero Trust Pioneer</strong></p>
    <ul>
        <li>This plan combines all features from the Zero Trust Explorer version.</li>
        <li>It offers the following additional features:</li>
        <ul>
            <li>Token lifecycle management.</li>
            <li>Health check monitoring.</li>
            <li>App-to-app integration monitoring.</li>
            <li>Dedicated data pane per tenant.</li>
            <li>Secret scanner for enhanced security.</li>
        </ul>
        <li>Price: Please contact us for pricing information.</li>
    </ul>

    <h3>3. Modification to Services and Prices</h3>
    <p> - LaunchIAM Inc. is committed to keeping the prices for the Local Lite version unchanged, and it will remain free of charge.</p>
    <p> - LaunchIAM Inc. reserves the right to modify, update, or discontinue any of the KeyRunner versions or their features at its sole discretion.</p>
    <p> - LaunchIAM Inc. may change the pricing for the Zero Trust Explorer and Zero Trust Pioneer versions. Any changes to pricing will be communicated to users in advance.</p>

    <h3>4. Acceptance of Terms</h3>
    <p>By using KeyRunner, you accept and agree to be bound by these Terms. If you do not agree with these Terms, please refrain from using KeyRunner.</p>

    <h3>5. Governing Law</h3>
    <p>These Terms are governed by the laws of the United States of America. Any legal disputes arising from the use of KeyRunner will be subject to the jurisdiction of the competent courts within the United States.</p>

    <h3>6. Intellectual Property</h3>
    
    <h4>a) Ownership</h4>
    <p> - All intellectual property rights associated with KeyRunner, including but not limited to copyrights, trademarks, patents, and trade secrets, are owned by LaunchIAM Inc. These rights are protected by United States and international intellectual property laws.</p>
    
    <h4>b) Use of KeyRunner</h4>
    <p> - LaunchIAM Inc. grants you a limited, non-exclusive, non-transferable, and revocable license to use KeyRunner in accordance with these Terms. This license does not grant you any ownership rights or rights to modify, reproduce, or distribute KeyRunner, except as expressly allowed in these Terms.</p>
    
    <h4>c) User Content</h4>
    <p> - You retain all intellectual property rights in the content you create, upload, or share through KeyRunner. LaunchIAM Inc. does not claim ownership of your content. By using KeyRunner, you grant LaunchIAM Inc. a worldwide, non-exclusive, royalty-free license to use, reproduce, and display your content for the purpose of providing and improving KeyRunner.</p>
    
    <h4>d) Feedback</h4>
    <p> - If you provide LaunchIAM Inc. with any feedback or suggestions regarding KeyRunner, LaunchIAM Inc. may use that feedback or suggestion without any obligation to compensate you or keep it confidential.</p>
    
    <h4>e) Reporting Intellectual Property Violations</h4>
    <p> - LaunchIAM Inc. respects the intellectual property rights of others and expects its users to do the same. If you believe that your intellectual property rights have been infringed upon by a user of KeyRunner, please contact LaunchIAM Inc. at [contact@email.com] with the relevant details.</p>
    
    <h4>f) Infringement Policy</h4>
    <p> - LaunchIAM Inc. may, at its discretion, remove or disable access to any content that is alleged to infringe on the intellectual property rights of others, and may terminate the accounts of users who repeatedly infringe on the rights of others.</p>
    
    <h4>g) Export Compliance</h4>
    <p> - You agree to comply with all applicable export control laws and regulations in your use of KeyRunner. You are responsible for ensuring that your use of KeyRunner does not violate any export laws and regulations.</p>
    
    <h4>h) Third-Party Intellectual Property</h4>
    <p> - KeyRunner may include third-party software or components. Your use of such third-party components is subject to the respective third-party terms and licenses, and LaunchIAM Inc. does not grant you any additional rights in relation to such third-party software.</p>
    
    <h3>7. Account Creation for Zero Trust Explorer and Pioneer</h3>
    
    <h4>a) Data Handling and Storage</h4>
    <p> - When creating an account for the Zero Trust Explorer and Pioneer versions of KeyRunner, it is important to note that LaunchIAM Inc. does not collect sensitive data, such as secrets, keys, or any other confidential information.</p>
    <p> - All sensitive data provided by users during the use of KeyRunner will be stored in the Data Pane, which is a component residing in your client environment. No cloud storage or external servers are involved in storing customer data.</p>
    <p> - This approach is designed to enhance the security and privacy of your sensitive information, ensuring that it remains under your control at all times.</p>
    
    <h4>b) Account Information</h4>
    <p> - When you create an account for the Zero Trust Explorer and Pioneer versions, you may be required to provide certain information, such as your username, email address, and other account-related details.</p>
    <p> - This account information will be used for account management and communication purposes and will not be used to collect or store sensitive data.</p>
    
    <h4>c) Confidentiality</h4>
    <p> - LaunchIAM Inc. places a high value on the confidentiality and security of your data. We do not access, view, or store your sensitive data. Our commitment is to ensure the protection and privacy of your information.</p>
    
    <h4>d) User Responsibilities</h4>
    <p> - Users are responsible for managing and securing their account credentials. Please take necessary precautions to protect your account from unauthorized access.</p>
    
    <h4>e) Data Ownership</h4>
    <p> - You retain full ownership and control of the data stored within your Data Pane. LaunchIAM Inc. does not claim any ownership or rights over your data.</p>
    
    <h4>f) Compliance</h4>
    <p> - LaunchIAM Inc. is committed to maintaining compliance with relevant data protection and privacy regulations. By using KeyRunner, you agree to abide by our data handling and storage practices, as described in these Terms.</p>
    
    <p>The account creation process for the Zero Trust Explorer and Pioneer versions of KeyRunner is designed with a strong focus on data security, confidentiality, and user control. Rest assured that sensitive data remains in your hands and is not stored in any cloud or external environment. Your privacy and data security are of utmost importance to LaunchIAM Inc.</p>
	
	 <h3>8. Contact Information</h3>
    <p>If you have any questions or concerns regarding these Terms of Use or KeyRunner, please contact LaunchIAM Inc. at [contact@launchiam.com].</p>
    </div>
  );
}

export default TermsOfUse;
