import {
  BrowserRouter,
  Router,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import FeaturePage from "./pages/Features/FeaturePage";
import Contactus from "./pages/Contact/Contactus";
import UserLogin from "./components/Login/UserLogin";
import Cases from "./pages/UseCases/Cases";
import Company from "./pages/Company/Company";
import TOU from "./pages/Tou/TOU";
import PrivacyPolicy from "./pages/Privacy Policy/Privacypolicy";
import License from "./pages/License/license";
import Pricebox from "./pages/Pricing/pricesection/Pricebox"
import DataProcessingAgreement from "./pages/DPA/dpa";
import CookiePolicy from "./pages/Cookie Policy/cookiepolicy";


function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/features" element={<FeaturePage />} />
          <Route path="/usecases" element={<Cases />} />
          <Route path="/company" element={<Company />} />
          <Route path="/pricing" element={ <Pricebox />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/login" element={<UserLogin />} />
          <Route path="/TOU" element={<TOU />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/CookiePolicy" element={<CookiePolicy />} />
          <Route path="/dpa" element={<DataProcessingAgreement />} />
          <Route path="/License" element={<License />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
