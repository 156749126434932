import React from "react";
import "../../Company/Company.css";
import comboimage from "../../../assets/images/sideimg.png";

export default function About() {
  return (
    <section className="fullsection aboutbox">
      <div className="aboutinner">
        <div className="maxcontainer mx-auto flex flex-col lg:flex-row justify-center space-y-4 md:space-y-0">
          <div className="w-full lg:w-1/2 aboutleft">
            <h1 className="maintitle">
              About <br /> <span className="bgtxt">Keyrunner</span>
            </h1>
            <p className="">
             Keyrunner, where security is more than a feature – Prepare for a transformative shift in your organization's approach to client secrets, 
              API keys, Non-Human identities, and PII data .Keyrunner is the only tool that will revolutionize the way teams operate securely 
              without compromising sensitive data. An all-in-one solution for API integrations,API Management, Continuous Testing, monitoring, and oversight of tokens lifecycle .
              An exclusive product designed for teams that serves various purposes without requiring manual entry of secret keys or API keys, which could accidentally lead to their exposure.
            </p>
            <p>
              <i>"Guarding the digital gates, secret keys and API keys stand as
              sentinels, preserving the integrity of your data in the realm of
              zeros and ones"</i>
            </p>
            <p>
              
            </p>
          </div>

        

          {/* <div className="w-full lg:w-1/2 aboutright">
            <img src={comboimage} alt="Banner" className="w-full h-auto" />
          </div> */}
        </div>
      </div>
    </section>
  );
}
