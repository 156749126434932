import React, { useState, useEffect } from "react";
import featureimg1 from "../../../assets/images/featureimg1.png";
import featureimg2 from "../../../assets/images/featureimg2.png";
import featureimg3 from "../../../assets/images/featureimg3.png";
import featureimg4 from "../../../assets/images/featureimg4.png";
import arrow1 from "../../../assets/images/steparrow1.svg";
import arrow2 from "../../../assets/images/steparrow2.svg";
// import arrow3 from "../../../assets/images/steparrow3.svg";
import "animate.css/animate.min.css";
import "../Homepage.css";

export default function Featurebox() {
    const [fadeIn1, setFadeIn1] = useState(false);
    const [fadeIn2, setFadeIn2] = useState(false);
    const [fadeIn3, setFadeIn3] = useState(false);
    const [fadeIn4, setFadeIn4] = useState(false);
    const [fadeInInner1, setFadeInInner1] = useState(false);
    const [fadeInInner2, setFadeInInner2] = useState(false);
    const [fadeInInner3, setFadeInInner3] = useState(false);
  
    useEffect(() => {
      // Use setTimeout to trigger the fade-in animations sequentially on page load
      const timeout1 = setTimeout(() => {
        setFadeIn1(true);
      }, 1000);
      const timeoutInner1 = setTimeout(() => {
        setFadeInInner1(true);
      }, 5000);
  
      const timeout2 = setTimeout(() => {
        setFadeIn2(true);
      }, 2000);
      const timeoutInner2 = setTimeout(() => {
        setFadeInInner2(true);
      }, 6000);
  
      const timeout3 = setTimeout(() => {
        setFadeIn3(true);
      }, 3000);
      const timeoutInner3 = setTimeout(() => {
        setFadeInInner3(true);
      }, 7000);
      const timeout4 = setTimeout(() => {
        setFadeIn4(true);
      }, 4000);
  
      return () => {
        clearTimeout(timeout1);
        clearTimeout(timeout2);
        clearTimeout(timeout3);
        clearTimeout(timeout4);
        clearTimeout(timeoutInner1);
        clearTimeout(timeoutInner2);
        clearTimeout(timeoutInner3);
      };
    }, []);
    return (
      <section className="fullsection featureboxx">
        <div className="featureboxxinner">
          <div className="maxcontainer mx-auto text-center">
            <h1 className="maintitle">
              With all the <span className="bgtxt">features</span> you need
            </h1>
            <p className="subtext">
              Securely shift left while retaining your sensitive data's<br></br> Integrity
            </p>
            <div className="featurepart">
              <div
                className={`box ${
                  fadeIn1 ? "animate__animated animate__fadeInUp" : ""
                }`}
              >
                <div className="featurestep featureblkbox featurestep1">
                  <div className="featurestepimg">
                    <img
                      src={featureimg1}
                      alt="Banner"
                      className="w-full h-auto"
                    />
                  </div>
                  <div className="featurecontent">
                    <h3>Powerful Dashboards</h3>
                    <p>
                      Data Visualization & Dashboards to address issues and
                      Downtimes
                    </p>
                  </div>
                </div>
                <div
                  className={`inner-box ${
                    fadeInInner1 ? "animate__animated animate__fadeIn" : ""
                  }`}
                >
                  <div className="steparrow1">
                    <img src={arrow1} alt="arrow1" className="w-full h-auto" />
                  </div>
                </div>
              </div>
              <div
                className={`box ${
                  fadeIn2 ? "animate__animated animate__fadeInUp" : ""
                }`}
              >
                <div
                  className={`inner-box ${
                    fadeInInner2 ? "animate__animated animate__fadeIn" : ""
                  }`}
                >
                  <div className="steparrow2">
                    <img src={arrow2} alt="arrow1" className="w-full h-auto" />
                  </div>
                </div>
                <div className="featurestep featurestep2">
                  <div className="featurestepimg">
                    <img
                      src={featureimg2}
                      alt="Banner"
                      className="w-full h-auto"
                    />
                  </div>
                  <div className="featurecontent">
                    <h3>App to App Connections</h3>
                    <p>
                    Verify Security of app-app integration involving non-human identities
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`box ${
                  fadeIn3 ? "animate__animated animate__fadeInUp" : ""
                }`}
              >
                <div className="featurestep featureblkbox featurestep3">
                  <div className="featurestepimg">
                    <img
                      src={featureimg3}
                      alt="Banner"
                      className="w-full h-auto"
                    />
                  </div>
                  <div className="featurecontent">
                    <h3>Alerts & Notifications</h3>
                    <p>
                    Receive alerts and notifications to proactively address potential issues
                    </p>
                  </div>
                </div>
                <div
                  className={`inner-box ${
                    fadeInInner3 ? "animate__animated animate__fadeIn" : ""
                  }`}
                >
                  <div className="steparrow1">
                    <img src={arrow1} alt="arrow3" className="w-full h-auto" />
                  </div>
                </div>
              </div>
              <div
                className={`box ${
                  fadeIn4 ? "animate__animated animate__fadeInUp" : ""
                }`}
              >
                <div className="featurestep featurestep4">
                  <div className="featurestepimg">
                    <img
                      src={featureimg4}
                      alt="Banner"
                      className="w-full h-auto"
                    />
                  </div>
                  <div className="featurecontent">
                    <h3>OOB Connectors</h3>
                    <p>
                    Seamless Out-of-box Connectors to fetch and utilize secrets
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
