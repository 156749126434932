import React from 'react'

export default function FeatureBanner() {
  return (
    <section className="fullsection processbox">
      <div className="">
      <div className="smcontainer mx-auto text-center">
      <h1 className="maintitle"><span className="bgtxt">With all the features</span><br /> you need </h1>
      <p className="subtext">Covers all scenarios involving sensitive data, eliminating the need for manual entry of secret keys or API keys.
      KeyRunner provides a lightweight interface for a secure API client. It offers essential features for secured API interaction while ensuring the security
       of your data. Sensitive data, like secret keys, API keys, tokens, etc., are always encrypted and stored securely on your local machine. 
       The Lite version is designed for users who require a simple yet secure solution.All the requests will run locally, and no sign-up or login is required for KeyRunner Local Lite. </p>
      </div>
      </div>
    </section>
  )
}
