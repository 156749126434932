import React, { useState } from "react";
import emailimg from "../../../assets/images/emailicon.svg";
import arrowimg from "../../../assets/images/arrow.svg";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Process() {
  const [email, setEmail] = useState(""); // State to store email value
  const process = React.useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_a7m7c3k",
        "template_lqozuk8",
        process.current,
        "7dqOb1u6E96TcXlQV"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast("Sent successfully");

          // Clear the email input field
          setEmail("");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section className="fullsection processbox">
      <div className="processboxinner">
        <div className="smcontainer mx-auto text-center">
          <h1 className="maintitle">
          Elevate<span className="bgtxt">Your API Security</span>to Unprecedented Heights{" "}
          </h1>
          {/* <p className="subtext">
            Codeless & Intelligent Test Automations
          </p> */}
          <form ref={process} onSubmit={sendEmail}>
            <>
              <div className="mail_layout">
                <div className="input-group input_mail">
                  <div className="input-group-prepend">
                    <img
                      src={emailimg}
                      alt="Email Icon"
                      className="custom-image"
                    />
                  </div>
                  <input
                    type="email"
                    required 
                    name="user_email"
                    className="form-control"
                    id="email"
                    placeholder="Your email"
                    value={email} // Bind the email value to the state
                    onChange={(e) => setEmail(e.target.value)} // Update state on input change
                  />
                </div>
              </div>
              <button
                className="ctabtn flex items-center submitbtn"
                
              >
                <span className="arrowimg">
                  <img
                    src={arrowimg}
                    alt="arrowimg"
                    className="w-full h-auto"
                  />
                </span>
                <span className="btntxt">Contact us for Zero Trust Explorer & Pioneer</span>
              </button>
            </>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="dark"
      />
    </section>
  );
}
