import React from "react";
import security from "../../assets/images/security.png";
import arrowimg from "../../assets/images/arrow.svg";
import "../UseCases/Cases.css";
import Process from "../Home/ProcessSection/Process";

export default function Cases() {
  return (
    <>
      <section className="fullsection casesbox">
        <div className="casesboxinner">
          <div className="maxcontainer mx-auto flex flex-col lg:flex-row justify-center space-y-4 md:space-y-0">
            <div className="w-full lg:w-3/5 caseleft">
              <h1 className="maintitle">
                <span className="bgtxt">Securely</span> use Secrets
              </h1>
              <p className="subtext">
                Safeguarding secrets against unauthorized access and internal threats is crucial for enhancing security and operational efficiency. By using a Zero Trust API Client, organizations can implement the following use cases:
              </p>
              <ul className="listopt">
                <li>
                  <i>
                    <span className="listarrowimg">
                      <img
                        src={arrowimg}
                        alt="arrowimg"
                        className="w-full h-auto"
                      />
                    </span>
                  </i>
                  <span className="">
                    <strong>Secure API Access Control:</strong> Enforce strict access controls for APIs, allowing only authorized users to access specific resources. Implement fine-grained access policies and role-based access control (RBAC).
                  </span>
                </li>
                <li>
                  <i>
                    <span className="listarrowimg">
                      <img
                        src={arrowimg}
                        alt="arrowimg"
                        className="w-full h-auto"
                      />
                    </span>
                  </i>
                  <span className="">
                    <strong>Data Privacy and Masking:</strong> Protect sensitive data by applying data masking techniques to API responses, ensuring that sensitive information is not exposed to unauthorized users.
                  </span>
                </li>
                <li>
                  <i>
                    <span className="listarrowimg">
                      <img
                        src={arrowimg}
                        alt="arrowimg"
                        className="w-full h-auto"
                      />
                    </span>
                  </i>
                  <span className="">
                    <strong>Scheduled API Testing:</strong> Automate the testing of APIs on a scheduled basis to proactively identify and address potential issues or vulnerabilities.
                  </span>
                </li>
                <li>
                  <i>
                    <span className="listarrowimg">
                      <img
                        src={arrowimg}
                        alt="arrowimg"
                        className="w-full h-auto"
                      />
                    </span>
                  </i>
                  <span className="">
                    <strong>Token Lifecycle Management:</strong> Manage the lifecycle of API tokens, including generation, revocation, and expiration, to maintain the security of API access.
                  </span>
                </li>
                <li>
                  <i>
                    <span className="listarrowimg">
                      <img
                        src={arrowimg}
                        alt="arrowimg"
                        className="w-full h-auto"
                      />
                    </span>
                  </i>
                  <span className="">
                    <strong>Health Check Monitoring:</strong> Continuously monitor the health and availability of APIs to ensure they are functioning as expected. Alert administrators to any issues that may arise.
                  </span>
                </li>
                <li>
                  <i>
                    <span className="listarrowimg">
                      <img
                        src={arrowimg}
                        alt="arrowimg"
                        className="w-full h-auto"
                      />
                    </span>
                  </i>
                  <span className="">
                    <strong>App-to-App Integration Monitoring:</strong> Monitor the integration between different applications that rely on APIs, ensuring that data flows smoothly and identifying integration issues.
                  </span>
                </li>
                <li>
                  <i>
                    <span className="listarrowimg">
                      <img
                        src={arrowimg}
                        alt="arrowimg"
                        className="w-full h-auto"
                      />
                    </span>
                  </i>
                  <span className="">
                    <strong>Dedicated Data Panes:</strong> Provide dedicated data panes for individual tenants or users to separate and secure their data, ensuring data isolation and privacy.
                  </span>
                </li>
                <li>
                  <i>
                    <span className="listarrowimg">
                      <img
                        src={arrowimg}
                        alt="arrowimg"
                        className="w-full h-auto"
                      />
                    </span>
                  </i>
                  <span className="">
                    <strong>Secret Scanner for Security:</strong> Scan APIs for potential security vulnerabilities, such as exposed secrets or sensitive information, and provide alerts and remediation options.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Process />
    </>
  );
}
