import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../Contact/Contactus.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Alert } from 'antd';

export default function Contactus() {
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm('service_a7m7c3k', 'template_rx72m9a', form.current, '7dqOb1u6E96TcXlQV').then(
			(result) => {
				console.log(result.text);
				toast('Sent succeessfully');
				form.current.reset();
			},
			(error) => {
				console.log(error.text);
			}
		);
	};
	return (
		<>
			<section className='fullsection contactbox'>
				<div className='contactboxinner'>
					<div className='maxcontainer mx-auto flex flex-col lg:flex-row justify-center space-y-4 md:space-y-0'>
						<div className='w-full lg:w-1/2 contactleft' style={{ padding: '50px' }}>
							<h1 className='maintitle'>
								Get In <span className='bgtxt'>Touch</span>
							</h1>
							<p className='subtext'>
								By Submitting this form, you acknowledge and agree to <br />
								LaunchIAM Privacy Policy
							</p>
							<div className='infobox'>
								<div className='infoparts'>
									<div>
										<i className='fa fa-envelope'></i>
									</div>
									<div>
										<h6>Email</h6>
										<a href='mailto: contact@keyrunner.app'>contact@keyrunner.app</a>
									</div>
								</div>
								{/* <div className="infoparts">
                  <div>
                    <i className="fa fa-phone"></i>
                  </div>
                  <div>
                    <h6>Phone</h6>
                    <a href="tel: 123456789">+123456789</a>
                  </div>
                </div> */}
								<div className='infoparts'>
									<div>
										<i className='fa fa-map-marker'></i>
									</div>
									<div>
										<h6>Office</h6>
										<a href=''>Austin , Texas</a>
									</div>
								</div>
							</div>
						</div>

						<div className='w-full lg:w-1/2 contactright'>
							<h3 className=''>Send Us a Message</h3>
							<p className='subtext'>Get in Touch with one of our representative</p>
							<form ref={form} onSubmit={sendEmail}>
								<input type='text' placeholder='Name' name='user_name' required />

								<input type='email' placeholder='Email' name='user_email' required />

								<input type='tel' placeholder='Phone' name='user_phone' required />

								<textarea name='message' placeholder='message' required />
								<input type='submit' value='Send' />
								<div
									style={{
										textAlign: 'center',
										padding: '10px',
										border: '1px solid #64B5F6',
										borderRadius: '10px',
										backgroundColor: '#BBDEFB',
									}}
								>
									<p style={{ fontSize: '12px', marginTop: '-1px' }}>
										By clicking send, you agree to our{' '}
										<a type='link' href='/privacypolicy' style={{ color: '#2C64CC' }}>
											privacy policy
										</a>{' '}
										and{' '}
										<a href='/TOU' style={{ color: '#2C64CC' }}>
											terms of service
										</a>
									</p>
								</div>
							</form>
							<ToastContainer
								className='custom-toast-container'
								autoClose={5000}
								newestOnTop
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								theme='dark'
							/>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
