import React, {useRef} from 'react';
import logo1 from '../../../assets/images/logo1.png';
import logo2 from '../../../assets/images/logo2.png';
import logo3 from '../../../assets/images/logo3.png';
import logo4 from '../../../assets/images/logo4.png';
import logo5 from '../../../assets/images/logo5.png';
import logo6 from '../../../assets/images/logo6.png';
import arrowimg from '../../../assets/images/arrow.svg';
import comboimage from '../../../assets/images/comboimg.png'
import '../Homepage.css'
import { Carousel } from 'antd';
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";

export default function Optimiseefficiency() {
  const carouselRef = React.useRef();

  const logoData = [
    { src: logo1, alt: 'logoimg1' },
    { src: logo2, alt: 'logoimg2' },
    { src: logo3, alt: 'logoimg3' },
    { src: logo4, alt: 'logoimg4' },
    { src: logo5, alt: 'logoimg5' },
    { src: logo6, alt: 'logoimg6' },
  ];


    return (
        <>
        <section className="fullsection optimisebox">
          <div className="optimiseboxinner">
         
            <div className="maxcontainer mx-auto flex flex-col lg:flex-row justify-center space-y-4 md:space-y-0"> 
            <div className="w-full lg:w-1/2 optimiseleft">
              <img src={comboimage} alt="Banner" className="w-full h-auto" />
            </div>
            
            <div className="w-full lg:w-1/2 optimiseright">
            <h1 className="maintitle">Optimize  <span className="bgtxt">DevSecOps</span> efficiency</h1>
              <p className="subtext">By seamlessly integrating monitoring, testing, and a multitude of other use cases, Keyrunner streamlines the entire process. It empowers teams to efficiently manage Identity and Access Management (IAM) for enhanced security, while also offering comprehensive testing and integration capabilities. With Keyrunner, teams can consolidate their efforts, reduce manual tasks, and ensure a more robust and secure development pipeline</p>
              <ul className="listopt listoptblk">
                <li>
                  <i>
                    <span className="listarrowimg"><img src={arrowimg} alt="arrowimg" className="w-full h-auto" /></span>
                </i>
                <span className="listtext">Intelligent test Automations . Codeless Testing methodologies</span>
                </li>
                <li>
                  <i>
                    <span className="listarrowimg"><img src={arrowimg} alt="arrowimg" className="w-full h-auto" /></span>
                </i>
                <span className="listtext">App-to-App Integration Monitoring & Health checks</span>
                </li>
                <li>
                  <i>
                    <span className="listarrowimg"><img src={arrowimg} alt="arrowimg" className="w-full h-auto" /></span>
                </i>
                <span className="listtext">Access token Lifecycle oversight</span>
                </li>
              </ul>
            </div>
    
            
            </div>
          
          </div>
        </section>
        <section className="fullsection brandlogo">
      <div className="brandlogoinner">
      <h1 className="maintitle maintitlewht">
          KeyRunner <span className="bgtxt">Integrates</span> With
          </h1>
         <div className="maxcontainer mx-auto">
      <Carousel
        autoplay
        slidesToShow={5}
        dots={false}
        ref={carouselRef}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
            },
          },
        ]}
      >
        {logoData.map((logo, index) => (
          <div key={index} className='logoimg'>
            <img src={logo.src} alt={logo.alt} className="carousel-image" />
          </div>
        ))}
      </Carousel>
      {/* <div className="carousel-nav">
        <button onClick={() => carouselRef.current.prev()} className='prenav'>
          <ArrowLeftOutlined />
        </button>
        <button onClick={() => carouselRef.current.next()} className='nextnav'>
          <ArrowRightOutlined />
        </button>
      </div> */}
    </div>
      </div>
    </section>
        </>
      );
}
