import React from "react";
import Card from "antd/es/card/Card";
import "../../Features/FeaturesPage.css";
import arrowimg from "../../../assets/images/arrow.svg";
import enterpriseimg from '../../../assets/images/enterprise.png'

export default function Pricebox() {
  return (
    <section className="fullsection pricebox">
      <div className="priceboxinner">
        <div className="smcontainer mx-auto text-center">
          <h1 className="maintitle">
            Pricing <span className="bgtxt">built</span>
            <br /> for businesses of all sizes{" "}
          </h1>
          <p className="subtext">
            We offer customizable pricing options suitable for organization of
            all sizes. Our pricing plans are <br />
            designed to meet your needs without breaking the bank. Get Started
            Today!
          </p>
          <div className="pricing-block">
            <Card className="pricing-card">
              <div className="priceparts pricepart1">
                <div className="priceheader">
                  <h6>Local Lite</h6>
                  <h3>
                    <p><b>Free Forever: No-cost access & no strings attached.</b></p>
                  </h3>
                  <h3>
                    <span>$</span>0<span>/user</span>
                  </h3>
                </div>
                <div className="pricelistbody">
                  <ul className="pricefeaturelist">
                  <li>
                      <i>
                        <span className="pricelistarrowimg">
                          <img
                            src={arrowimg}
                            alt="arrowimg"
                            className="w-auto h-auto"
                          />
                        </span>
                      </i>
                      <p> No Signup or Login</p>
                    </li>
                    <li>
                      <i>
                        <span className="pricelistarrowimg">
                          <img
                            src={arrowimg}
                            alt="arrowimg"
                            className="w-auto h-auto"
                          />
                        </span>
                      </i>
                      <p>VS Code Extension & Desktop Apps</p>
                    </li>
                    <li>
                      <i>
                        <span className="pricelistarrowimg">
                          <img
                            src={arrowimg}
                            alt="arrowimg"
                            className="w-auto h-auto"
                          />
                        </span>
                      </i>
                      <p> Local Storage & Local Execution</p>
                    </li>
                    
                    <li>
                      <i>
                        <span className="pricelistarrowimg">
                          <img
                            src={arrowimg}
                            alt="arrowimg"
                            className="w-full h-auto"
                          />
                        </span>
                      </i>
                      <p> Run Unlimited collections</p>
                    </li>
                    <li>
                      <i>
                        <span className="pricelistarrowimg">
                          <img
                            src={arrowimg}
                            alt="arrowimg"
                            className="w-full h-auto"
                          />
                        </span>
                      </i>
                      <p>Mock Servers</p>
                    </li>
                    <li>
                      <i>
                        <span className="pricelistarrowimg">
                          <img
                            src={arrowimg}
                            alt="arrowimg"
                            className="w-full h-auto"
                          />
                        </span>
                      </i>
                      <p>Encrypted Env Variables</p>
                    </li>
                    <li>
                      <i>
                        <span className="pricelistarrowimg">
                          <img
                            src={arrowimg}
                            alt="arrowimg"
                            className="w-full h-auto"
                          />
                        </span>
                      </i>
                      <p>Scriptless Testing & Playground</p>
                    </li>
                    <li>
                      <i>
                        <span className="pricelistarrowimg">
                          <img
                            src={arrowimg}
                            alt="arrowimg"
                            className="w-full h-auto"
                          />
                        </span>
                      </i>
                      <p>GIT Integration</p>
                    </li>
                    <li>
                      <i>
                        <span className="pricelistarrowimg">
                          <img
                            src={arrowimg}
                            alt="arrowimg"
                            className="w-full h-auto"
                          />
                        </span>
                      </i>
                      <p>Secrets Scanner</p>
                    </li>
                  </ul>
                </div>
                <div className="pricingfooter">
                  <button className="ctabtn flex items-center">
                    <span className="arrowimg">
                      <img
                        src={arrowimg}
                        alt="arrowimg"
                        className="w-full h-auto"
                      />
                    </span>
                    <span className="btntxt">Get Started</span>
                  </button>
                </div>
              </div>
            </Card>
            <Card className="pricing-card">
              <div className="priceparts pricepart2">
                <div className="priceheader">
                  <h6>Zero Trust Explorer</h6>
                 
                  <p>This plan includes all Local Lite features. </p>
                  <h3>
                    <span>Contact us for pricing</span>
                  </h3>

                </div>
                <div className="pricelistbody">
                <ul className="pricefeaturelist">
                    <li>
                      <i>
                        <span className="pricelistarrowimg">
                          <img
                            src={arrowimg}
                            alt="arrowimg"
                            className="w-full h-auto"
                          />
                        </span>
                      </i>
                      <p>Single-Sign-On with organization Email , RBAC(Role Based Access Control), SCIM user provisioning </p>
                    </li>
                    <li>
                      <i>
                        <span className="pricelistarrowimg">
                          <img
                            src={arrowimg}
                            alt="arrowimg"
                            className="w-full h-auto"
                          />
                        </span>
                      </i>
                      <p>Centralized data pane to read secrets,keys ,sensitive data and execute requests without ever viewing them</p>
                    </li>
                    <li>
                      <i>
                        <span className="pricelistarrowimg">
                          <img
                            src={arrowimg}
                            alt="arrowimg"
                            className="w-full h-auto"
                          />
                        </span>
                      </i>
                      <p>Mask PII information in responses configured by data owner </p>
                    </li>
                    
                    <li>
                      <i>
                        <span className="pricelistarrowimg">
                          <img
                            src={arrowimg}
                            alt="arrowimg"
                            className="w-full h-auto"
                          />
                        </span>
                      </i>
                      <p>Scheduler to run regression tests </p>
                    </li>
                  </ul>
                </div>
                <div className="pricingfooter">
                  <button className="ctabtn flex items-center">
                    <span className="arrowimg">
                      <img
                        src={arrowimg}
                        alt="arrowimg"
                        className="w-full h-auto"
                      />
                    </span>
                    <span className="btntxt">Contact Us</span>
                  </button>
                </div>
              </div>
            </Card>
            <Card className="pricing-card">
            <div className="priceparts pricepart3">
                <div className="priceheader">
                <img src={enterpriseimg} alt="enterpriseimg" className="w-full h-auto" />
                  <h3>Zero Trust Pioneer</h3>
                </div>
                <div className="pricelistbody">
                  <p><b>Advanced Secure:</b> Combines all the features from Zero Trust Explorer with 
                    health checks, Monitoring, Custom Log retention, dedicated data pane per Business Unit</p>
                </div>
                <div className="pricingfooter">
                  <button className="ctabtn flex items-center">
                    <span className="arrowimg">
                      <img
                        src={arrowimg}
                        alt="arrowimg"
                        className="w-full h-auto"
                      />
                    </span>
                    <span className="btntxt">Contact Us</span>
                  </button>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
}
