import React from "react";
import "../../Company/Company.css";
import storyimg from '../../../assets/images/storyimg.svg'

export default function OurStory() {
  return (
    <section className="fullsection storybox">
      <div className="storyinner">
        <div className="maxcontainer mx-auto flex flex-col lg:flex-row items-center justify-center space-y-4 md:space-y-0">
          <div className="w-full lg:w-1/2 storyleft">
           <img src={storyimg} alt="storyimg" className="w-full h-auto" />
          </div>

          <div className="w-full lg:w-1/2 storyright">
            <h1 className="maintitle maintitlewht">
              Our <span className="bgtxt">Story</span>
            </h1>
            <p className="">
            It all started with a spark of innovation and a passionate team of tech enthusiasts. We recognized a growing need in the industry for a comprehensive solution that 
            could address the evolving challenges of API security, testing, monitoring, and regression in a world where secrets play a pivotal role. 
            This recognition led us on a journey to create something truly groundbreaking, and that's how Keyrunner was born.
            </p>
            <p>
            Our journey started with a simple question: how can we ensure that sensitive data, the lifeblood of modern enterprises, is safeguarded without compromise? 
            The answer lies in our revolutionary approach to secret management.That's why we set out to develop the industry's first and only Zero Trust REST API client.
            </p>
            <p>
            Say goodbye to the era of manual key entry, the weak link in many security chains. Our solution ensures that secret keys and API keys 
            are dynamically generated, never exposed to human eyes, and securely stored away from prying hands. It represents a significant shift that introduces a degree of 
            security that was previously believed to be beyond reach.
            </p>
            <p>
            We understand that in today's interconnected world, seamless communication between applications is paramount. Our app-to-app integration monitoring not 
            only facilitates this flow but does so while keeping your secrets secured and in client controlled environment. It's the harmony between convenience and 
            security that modern businesses crave.

            </p>
            <p>
            At Keyrunner, we don't just offer products – we offer peace of mind. As we continue to evolve and grow, our dedication to innovation, security, and customer satisfaction remains at the core of everything we do. 
            We're here to empower your business, protect your assets, and simplify the world of API security and testing.
             Join us on this journey to a future where your secrets remain truly secret, and your business operates without fear. Together, let's redefine security for the digital age.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
