import React, { useEffect, useRef } from 'react';
import mac from '../../../assets/images/requests.png';
import arrowimg from '../../../assets/images/arrow.svg';
import '../Homepage.css';

export default function Nonhuman() {

 

  return (
    <section className="fullsection apikeybox">
      <div className="apikeyboxinner">
        <div className="mx-auto">
          <h1 className="smcontainer maintitle maintitlewht">Safeguarding <span className="bgtxt">Secret keys & API keys</span></h1>
          <div className="smcontainer mx-auto flex flex-col lg:flex-row justify-center space-y-4 md:space-y-0">
            <div className="w-full lg:w-3/5">
              {/* <h1 className="maintitle maintitlewht">Secret keys & API keys</h1> */}
              <p className="subtext subtextwht">Beyond Detection, we ensure Non-Exposure of sensitive keys with minimal human access for maximum security. An exclusive product designed for teams that serves various purposes without requiring manual entry of secret keys or API keys, which could accidentally lead to their exposure.</p>
              <ul className="listopt">
                <li>
                  <i>
                    <span className="listarrowimg"><img src={arrowimg} alt="arrowimg" className="w-full h-auto" /></span>
                  </i>
                  <span className="listtext">80% of breaches are caused by leaked credentials.</span>
                </li>
                <li>
                  <i>
                    <span className="listarrowimg"><img src={arrowimg} alt="arrowimg" className="w-full h-auto" /></span>
                  </i>
                  <span className="listtext">Malicious actors often target these credentials to exploit vulnerabilities, potentially leading to data breaches and service disruptions.</span>
                </li>
                <li>
                  <i>
                    <span className="listarrowimg"><img src={arrowimg} alt="arrowimg" className="w-full h-auto" /></span>
                  </i>
                  <span className="listtext">Breached client secrets can lead to cross-platform attacks, affecting multiple services and organizations.</span>
                </li>
              </ul>
            </div>
      <div className="w-full lg:w-1/2 ">
          <img src={mac} alt="Banner" className="w-full h-auto" />
        </div>
          
          </div>
        </div>
      </div>
    </section>
  );
}
