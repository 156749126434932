import { Button } from 'antd';
import React, { useEffect } from 'react';
import Banner from './BannerSection/Banner';
import Featurebox from './FeaturesSection/Featurebox';
import HowItWorks from './HowItWorksSection/HowItWorks';
import Nonhuman from './NonHumanSection/Nonhuman';
import Optimiseefficiency from './OptimiseEfficiencySection/Optimiseefficiency';
import Playground from './PlaygroundSection/Playground';
import Process from './ProcessSection/Process';
import { cookie } from './cookie';

export default function Home() {
	useEffect(() => {
		cookie();
	}, []);

	return (
		<>
			<Banner />
			<Featurebox />
			<Nonhuman />
			<Playground />
			<HowItWorks />
			<Optimiseefficiency />
			<Process />
			<div
				class='cookie-banner'
				style={{ position: 'fixed', bottom: 20, left: 20, zIndex: 1000, width: '300px', display: 'none' }}
			>
				<div
					style={{
						backgroundColor: '#FAFAFA',
						border: '1px solid #FAFAFA',
						borderRadius: '10px',
						padding: '16px',
						boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
					}}
				>
					<p
						style={{
							color: 'gray',
							fontSize: '16px',
							fontWeight: 'normal',
							fontFamily: 'Arial, sans-serif',
							// textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
							textAlign: 'justify',
							marginBottom: '10px',
						}}
					>
						We use cookies to ensure you get the best experience on our website. By continuing to use our site, you
						agree to our use of cookies.
					</p>
					<div className='flex justify-between items-center'>
						<Button
							type='primary'
							className='cookie-banner-button'
							style={{
								backgroundColor: '#2C64CC',
								color: 'white',
								border: '1px solid #2C64CC',
								borderRadius: '10px',
								boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
								marginTop: '10px',
							}}
						>
							Accept
						</Button>
						<Button type='link' onClick={() => (window.location.href = '/privacypolicy')}>
							Privacy Policy
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}
