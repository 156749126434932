import React from 'react'
import FeatureBanner from './FeatureBannerSection/FeatureBanner';
import Faq from './FaqSection/Faq';
import VerticalTabs from './VerticalTabsSection/VerticalTabs';
import Pricebox from './PriceSection/Pricebox';
import Process from '../Home/ProcessSection/Process';

export default function FeaturePage() {
  return (
    <>
    <FeatureBanner />
    <VerticalTabs />
    <Faq />
    {/* <Pricebox /> */}
    <Process />
    </>
  )
}
