import React from "react";
import icon1 from "../../../assets/images/imageicon1.png";
import icon2 from "../../../assets/images/imageicon2.png";
import icon3 from "../../../assets/images/imageicon3.png";
import icon4 from "../../../assets/images/imageicon4.png";
import icon5 from "../../../assets/images/imageicon5.png";
import icon6 from "../../../assets/images/imageicon6.png";
import flowicon1 from "../../../assets/images/fliparrow1.svg";
import flowicon2 from "../../../assets/images/fliparrow2.svg";
import flowicon3 from "../../../assets/images/fliparrow3.svg";
import workstep1 from "../../../assets/images/workstepimg1.png";
import workstep2 from "../../../assets/images/workstepimg2.png";
import workstep3 from "../../../assets/images/workstepimg3.png";
// import flowicon4 from "../../../assets/images/flowicon4.svg";
import "../Homepage.css";

export default function HowItWorks() {
  return (
    <section className="fullsection workbox">
      <div className="workboxinner">
        <div className="smcontainer mx-auto">
          <h1 className="maintitle maintitlewht text-center">
            How It <span className="bgtxt">Works </span>
          </h1>
          <p className="subtext subtextwht text-center">
            Securely shift left while retaining your sensitive data's Integrity
          </p>
          <div className="flex worksmallbox text-center">
            <div className="w-full md:w-1/3 p-4">
              <div className="workstepimg ">
                <img src={workstep1} alt="Banner" className="w-full h-auto" />
              </div>
              <div className="workstepcontent">
                <h3>Create a collection</h3>
                <p>
                  Create requests with inputs & Expected outputs or use our
                  Integrations to clone any use case
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-4">
              <div className="workstepimg ">
                <img src={workstep2} alt="Banner" className="w-full h-auto" />
              </div>
              <div className="workstepcontent">
                <h3>Use Drag & Drop</h3>
                <p>
                  use our interactive playground feature to play around with the
                  edge cases to ensure all the edge cases are covered
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-4">
              <div className="workstepimg ">
                <img src={workstep3} alt="Banner" className="w-full h-auto" />
              </div>
              <div className="workstepcontent">
                <h3>Run the collection</h3>
                <p>
                  Run the collection to identify any errors and add it to
                  regression suite for scheduled runs
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="mainflipbox">
              <div className="flipbox">
                <div className="container">
                  <div className="flip-box flipbox1">
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <div className="frnt">
                          <img
                            src={icon1}
                            alt="flipicon1"
                            className="flipiconimg"
                          />
                          <h2>Integration and Development</h2>
                        </div>
                      </div>
                      <div className="flip-box-back">
                        <p>
                          Seamlessly integrate and develop with confidence,
                          knowing that KeyRunner
                          product shields secrets and sensitive data from human
                          exposure, strengthening your security posture.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flowicon1 ">
                    <img
                      src={flowicon1}
                      alt="flowicon1 "
                      className="flowicon"
                    />
                  </div>
                  <div className="downicon1">
                    <img src={flowicon3} alt="flowicon3" className="flowicon" />
                  </div>

                  <div className="flip-box flipbox2">
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <div className="frnt">
                          <img
                            src={icon2}
                            alt="flipicon2"
                            className="flipiconimg"
                          />
                          <h2>Generate the keys dynamically</h2>
                        </div>
                      </div>
                      <div className="flip-box-back">
                        <p>
                          Enhance security by dynamically generating keys,
                          and fetching them with KeyRunner's datapane to process any request.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flowicon2">
                    <img
                      src={flowicon2}
                      alt="flowicon2 "
                      className="flowicon"
                    />
                  </div>

                  <div className="flip-box flipbox3">
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <div className="frnt">
                          <img
                            src={icon3}
                            alt="flipicon3"
                            className="flipiconimg"
                          />
                          <h2>Add To Scheduled regression testing</h2>
                        </div>
                      </div>
                      <div className="flip-box-back">
                        <p>
                          Add scheduled regression testing to your processes without manually inputting any of the sensitive data,
                          fortifying your application's defense mechanisms.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flipbox">
                <div className="container">
                  <div className="flip-box flipbox1">
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <div className="frnt">
                          <img
                            src={icon4}
                            alt="flipicon4"
                            className="flipiconimg"
                          />
                          <h2>Playground</h2>
                        </div>
                      </div>
                      <div className="flip-box-back">
                        <p>
                          Build trees and nodes with existing API collections, a
                          drag and drop functionality to play around with test
                          cases and never to miss an edge case.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flowicon1 ">
                    <img
                      src={flowicon1}
                      alt="flowicon1 "
                      className="flowicon"
                    />
                  </div>

                  <div className="flip-box flipbox2">
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <div className="frnt">
                          <img
                            src={icon5}
                            alt="flipicon5"
                            className="flipiconimg"
                          />
                          <h2>OAuth Access Token Lifecycle Oversight</h2>
                        </div>
                      </div>
                      <div className="flip-box-back">
                        <p>
                        Keyrunner Platform offers overseeing the entire OAuth
                          access token lifecycle.Our monitoring solutions ensure
                          tokens' proper issuance, usage, and revocation,
                          maintaining API security.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flowicon2">
                    <img
                      src={flowicon2}
                      alt="flowicon2 "
                      className="flowicon"
                    />
                  </div>

                  <div className="flip-box flipbox3">
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <div className="frnt">
                          <img
                            src={icon6}
                            alt="flipicon6"
                            className="flipiconimg"
                          />
                          <h2>
                            Monitor the application integration (app to app
                            integrations)
                          </h2>
                        </div>
                      </div>
                      <div className="flip-box-back">
                        <p>
                          Keep a vigilant eye on application integrations,
                          especially in app-to-app scenarios, Tokens in use &
                          Tokens lifecycle management
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="downicon2">
                    <img src={flowicon3} alt="flowicon3" className="flowicon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
