import React from 'react';
import './license.css';

function License() {
  return (
    <div className="license">
  <h1>End User License Agreement</h1>

<h2>1. Acceptance of Terms</h2>
<p>This End User License Agreement ("EULA") is a legal agreement between you and LaunchIAM Inc., a company registered in the United States of America, regarding the use of KeyRunner, a product developed by LaunchIAM Inc. By using KeyRunner, you agree to comply with the terms and conditions outlined in this EULA. If you do not agree with these terms, please refrain from using KeyRunner.</p>

<h2>2. KeyRunner Versions</h2>
<p>KeyRunner is available in three different versions:</p>

<p><strong>a) Local Lite</strong></p>
<ul>
    <li>This version is completely free to use.</li>
    <li>It offers local storage capabilities.</li>
    <li>No signup or registration is required.</li>
</ul>

<p><strong>b) Zero Trust Explorer</strong></p>
<ul>
    <li>This plan includes all features of the Local Lite version.</li>
    <li>It provides the following additional features:</li>
    <ul>
        <li>Secure Sockets (SS)</li>
        <li>Role-Based Access Control (RBAC)</li>
        <li>Centralized data pane for reading secret keys and sensitive data without exposing them to users.</li>
        <li>PII information masking in responses, which can be configured by the data owner.</li>
        <li>Scheduler for running regression tests.</li>
    </ul>
    <li>Price: $10 per user/tenant.</li>
</ul>

<p><strong>c) Zero Trust Pioneer</strong></p>
<ul>
    <li>This plan combines all features from the Zero Trust Explorer version.</li>
    <li>It offers the following additional features:</li>
    <ul>
        <li>Token lifecycle management.</li>
        <li>Health check monitoring.</li>
        <li>App-to-app integration monitoring.</li>
        <li>Dedicated data pane per tenant.</li>
        <li>Secret scanner for enhanced security.</li>
    </ul>
    <li>Price: Please contact us for pricing information.</li>
</ul>

<h2>3. Modification to Services and Prices</h2>
<p> - LaunchIAM Inc. is committed to keeping the prices for the Local Lite version unchanged, and it will remain free of charge.</p>
<p> - LaunchIAM Inc. reserves the right to modify, update, or discontinue any of the KeyRunner versions or their features at its sole discretion.</p>
<p> - LaunchIAM Inc. may change the pricing for the Zero Trust Explorer and Zero Trust Pioneer versions. Any changes to pricing will be communicated to users in advance.</p>

<h2>4. Definitions</h2>
<p>In this EULA:</p>
<ul>
    <li><strong>"KeyRunner"</strong> refers to the software product developed by LaunchIAM Inc., including all its versions and components.</li>
    <li><strong>"User" or "You"</strong> refers to any individual or entity using KeyRunner.</li>
    <li><strong>"Data Pane"</strong> refers to the component within KeyRunner used for storing sensitive data.</li>
    <li><strong>"Sensitive Data"</strong> refers to confidential information, secrets, and any data that may require protection.</li>
</ul>

<h2>5. Ownership</h2>
<p>All intellectual property rights associated with KeyRunner, including but not limited to copyrights, trademarks, patents, and trade secrets, are owned by LaunchIAM Inc. These rights are protected by United States and international intellectual property laws.</p>

<h2>6. Grant of License</h2>
<p>LaunchIAM Inc. grants you a limited, non-exclusive, non-transferable, and revocable license to use KeyRunner in accordance with the terms and conditions outlined in this EULA. This license does not grant you any ownership rights or rights to modify, reproduce, or distribute KeyRunner, except as expressly allowed in this EULA.</p>

<h2>7. Intended Users of Software</h2>
<p>KeyRunner is intended for use by individuals and entities who require secure access to APIs and the management of sensitive data. The software is designed for professional and business use, and users are expected to comply with all applicable laws and regulations in their use of KeyRunner.</p>

<h2>8. Installation of Software</h2>
<p>You may install and use KeyRunner on your own computing equipment or within your own client environment. Installation on third-party servers or cloud platforms is not permitted without express written consent from LaunchIAM Inc.</p>

<h2>9. Restrictions on Use of Software</h2>
<p>You are expressly prohibited from:</p>
<ul>
    <li>Reverse engineering, decompiling, or attempting to derive the source code of KeyRunner.</li>
    <li>Modifying, adapting, translating, or creating derivative works based on KeyRunner.</li>
    <li>Distributing, selling, sublicensing, renting, or leasing KeyRunner or any part of it.</li>
    <li>Removing or altering any proprietary notices or labels on KeyRunner.</li>
    <li>Using KeyRunner for any unlawful, malicious, or unethical purposes.</li>
</ul>

<h2>10. Limited Warranty and Disclaimer of Warranty</h2>
<p>KeyRunner is provided "as is," without any warranties, whether expressed or implied, including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose. LaunchIAM Inc. does not warrant that the software will meet your requirements or operate error-free. Your use of KeyRunner is at your own risk.</p>

<h2>11. Disclaimer of Damages</h2>
<p>In no event shall LaunchIAM Inc. be liable for any direct, indirect, incidental, special, or consequential damages, or damages for loss of profits, revenue, data, or use, incurred by the user or any third party, even if LaunchIAM Inc. has been advised of the possibility of such damages.</p>

   <h2>12. Termination</h2>
<p>This EULA is effective until terminated by you or LaunchIAM Inc. You may terminate this EULA at any time by discontinuing the use of KeyRunner and destroying all copies of the software. LaunchIAM Inc. may terminate this EULA if you fail to comply with any of its terms and conditions.</p>

<h2>13. Payment</h2>
<p>Unless otherwise specified, the use of the Local Lite version of KeyRunner is free of charge. The pricing for the Zero Trust Explorer and Zero Trust Pioneer versions is as outlined in Section 2 of this EULA. Payments are to be made as specified by LaunchIAM Inc.</p>

<h2>14. Relationship of Parties</h2>
<p>Your use of KeyRunner does not create a partnership, agency, or employment relationship between you and LaunchIAM Inc. You do not have the authority to bind or represent LaunchIAM Inc. in any way.</p>

<h2>15. Governing Law</h2>
<p>This EULA is governed by the laws of the United States of America. Any legal disputes arising from the use of KeyRunner will be subject to the jurisdiction of the competent courts within the United States.</p>

<h2>16. Entire Agreement</h2>
<p>This EULA constitutes the entire agreement between you and LaunchIAM Inc. regarding the use of KeyRunner and supersedes all prior agreements, understandings, and communications, whether oral or written.</p>
    </div>
  );
}

export default License;
