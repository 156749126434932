import React from 'react';

const DataProcessingAgreement = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', fontFamily: 'Arial, sans-serif' }}>
      <h1>Data Processing Agreement</h1>
      <p>
        This Data Processing Agreement (“DPA”) is entered into between <strong>Launchiam Inc.</strong>, the provider of
        KeyRunner (a zero-trust API client), and you (“Customer” or “Data Controller”) and governs the processing of
        personal data by KeyRunner in accordance with applicable data protection laws.
      </p>
      
      <h2>1. Data Collection</h2>
      <p>
        KeyRunner does not store any sensitive data or Personally Identifiable Information (PII) within the app or its
        cloud. The only PII collected by KeyRunner is limited to the following:
      </p>
      <ul>
        <li>Name</li>
        <li>Email Address</li>
        <li>Phone Number</li>
      </ul>
      <p>
        This information is collected solely when users fill out the <strong>Contact Us</strong> form for marketing and product updates. No other PII is processed by the KeyRunner application itself.
      </p>

      <h2>2. Data Storage</h2>
      <p>
        All customer data is stored locally on the customer’s machine. For enterprise customers, minimal data is stored in KeyRunner’s cloud
        services, which is used solely to enable features such as sharable workspaces and collaboration functionalities.
      </p>
      <p>
        The data stored in KeyRunner’s cloud is kept to a minimum and strictly limited to what is necessary to enable
        these features.
      </p>

      <h2>3. Data Processing</h2>
      <p>
        KeyRunner operates as a zero-trust API client and does not store or process sensitive customer data such as API
        keys, tokens, or any other secure information. All sensitive data is kept on the customer’s local environment.
      </p>
      <p>
        Any personal data collected via the <strong>Contact Us</strong> form is processed solely for the purposes of marketing, product
        updates, and communication with the customer. This data is not shared with any third parties unless explicitly
        required by law.
      </p>

      <h2>4. International Use</h2>
      <p>
        KeyRunner is available as a downloadable extension for Visual Studio Code, as well as for Windows and Mac operating
        systems. The app is accessible in all countries. Data protection laws may vary between regions; however, KeyRunner
        commits to processing personal data in accordance with applicable local laws, including but not limited to GDPR
        (General Data Protection Regulation) for customers in the European Union.
      </p>

      <h2>5. Data Protection Officer (DPO)</h2>
      <p>
        Launchiam Inc. has appointed <strong>Krishna Abburi</strong> as the Data Protection Officer (DPO). If you have any questions or
        concerns regarding data processing or data protection, you may contact the DPO at: <br />
        <strong>Email:</strong> dpo@keyrunner.app<br />
        <strong>Phone:</strong> +1-832-914-4185
      </p>

      <h2>6. Customer Rights</h2>
      <p>
        As the data controller, you have the right to request access to, rectify, or delete the personal data we process
        about you. You also have the right to restrict or object to the processing of your personal data. Requests can be
        sent to our Data Protection Officer using the contact information provided above.
      </p>

      <h2>7. Amendments</h2>
      <p>
        This DPA may be updated from time to time in compliance with applicable data protection laws. Any changes will be
        communicated to customers in a timely manner.
      </p>

      <h2>8. Contact Information</h2>
      <p>
        If you have any questions about this DPA, please contact us at: <br />
        <strong>Email:</strong> contact@keyrunner.app <br />
        <strong>Address:</strong> Launchiam Inc., 10601 Clarence Dr. Suite 250,Frisco, TX 75033
      </p>
    </div>
  );
};

export default DataProcessingAgreement;
