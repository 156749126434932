// all cookie functionality goes here
export const cookie = () => {
	if (document.cookie.includes('keyrunnercookie')) {
		document.querySelector('.cookie-banner').style.display = 'none';
		return;
	}

	const existingCookies = document.cookie.split(';');
	console.log('mylogs :: 🚀 ::  :: cookie :: existingCookies:', existingCookies);

	document.querySelector('.cookie-banner').style.display = 'block';
	document
		.querySelector('.cookie-banner')
		.querySelector('.cookie-banner-button')
		.addEventListener('click', () => {
			document.cookie =
				'keyrunnercookie=true; expires=' + new Date(Date.now() + 1000 * 60 * 60 * 24 * 30).toUTCString(); // 1 month
			document.querySelector('.cookie-banner').style.display = 'none';
		});
};
